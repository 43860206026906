<template>
  <div id="showImage">
    <div class="show-big" v-if="showBigImage" @click="close">
      <img id="bigImage" :src="url" @click="close" />
    </div>
  </div>
</template>

<script>
/**
 * @module components/showImage
 * @desc 传蔬农贸版 图片放大显示
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-10-26
 * @copyright 2021
 */
export default {
  name: "showimage",
  props: {
    url: {
      type: String,
      default: "",
    },
    imgHeight: {
      type: Number,
      default: 150,
    },
    showBigImage: {
      type: Boolean,
      default: false,
    },
    closeCallBack: {},
  },
  methods: {
    /**
     * 关闭弹出层
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-26
     */
    close() {
      this.closeCallBack();
    },
  },
};
</script>

<style lang="less">
@import url("../less/common.less");
</style>
